<template>
  <div class="tile">
    <img :src="thumbPath" class="image" />
    <div class="overlay">
      <h3>{{ title }}</h3>
      <p>{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectTile",
  props: {
    thumbPath: String,
    desc: String,
    title: String,
    tags: [],
  },
};
</script>

<style>
.tile {
  text-align: center;
  overflow: hidden;
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f6f3f2;
  overflow: hidden;
  left: -100%;
  width: 100%;
  height: 100%;
  transition: 0.5s cubic-bezier(1, 0, 0, 1);
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tile:hover .overlay {
  left: 0;
}

.tile p {
  padding: 10px;
  padding-top: 0px;
  text-align: justify;
  text-align-last: center;
}
</style>
