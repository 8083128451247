<template>
  <div class="home-page">
    <div class="filterContainer">
      <button
        v-for="filter in filters"
        :key="filter"
        @click="filterProjects(filter)"
        v-bind:class="{ active: currentFilter === filter }"
      >
        {{ filter }}.
      </button>
    </div>
    <div class="projects-container">
      <TransitionGroup name="filter-anim" mode="in-out">
        <div
          class="tileCont"
          v-for="project in currentProjects"
          :key="project.title"
        >
          <router-link :to="'/project/' + project.id + '/' + project.routeName">
            <ProjectTile
              :thumbPath="project.thumbPath"
              :title="project.title"
              :desc="project.desc"
              :tags="project.tags"
            ></ProjectTile>
          </router-link>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import ProjectTile from "@/components/ProjectTile.vue";
import json from "../assets/projects.json";
import { TransitionGroup } from "vue";

export default {
  name: "HomePage",
  components: {
    ProjectTile,
    TransitionGroup,
  },
  methods: {
    filterProjects(filter) {
      this.currentFilter = filter;

      if (filter === "all") {
        this.currentProjects = this.projects;
        return;
      }
      this.currentProjects = this.projects.filter((project) => {
        return project.tags.includes(filter);
      });
      return;
    },
  },
  data() {
    return {
      currentFilter: "all",
      projects: json.projects,
      currentProjects: json.projects,
      filters: ["all", "sound", "code", "visual", "AI"],
    };
  },
};
</script>

<style lang="scss">
.filter-anim-enter,
.filter-anim-leave-to

/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.filter-anim-leave-active {
  position: absolute;
}

.home-page {
  width: 100%;

  .projects-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    .tileCont {
      transition: all 0.5s;
      width: 32%;

      a {
        :hover {
          color: #2c3e50;
        }
      }
    }

    span {
      transition: all 1s;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }

  .filterContainer {
    width: 80%;
    display: flex;

    button {
      text-transform: uppercase;
      font-size: 0.9rem;
      margin-right: 10px;
      background: transparent;
      border: none;
      display: inline;

      &.active {
        font-weight: bold;
      }

      &:hover,
      &:focus,
      &:enabled {
        border: none;
        outline: none;
      }
    }
  }

  @media only screen and (max-width: 736px) {
    .filterContainer {
      width: 100%;
      flex-wrap: wrap;
    }

    margin-top: 10px;
  }
}
</style>
